<template>
  <ReviewerNavBar tab="Evaluation" />
  <div class="w-screen bg-white flex items-center justify-center">
    <h1></h1>
  </div>
</template>
<script>
import ReviewerNavBar from "./ReviewerNavBar.vue";
export default {
  components: {
    ReviewerNavBar,
  },
};
</script>
